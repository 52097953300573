<template>
  <section class="viewing-room-artwork">
    <!-- <Loader :loading="loading" /> -->
    <div class="viewing-room-artwork__header">
      <h1 v-if="viewingRoomArtwork.viewing_room.name" class="viewing-room-artwork__header_title">
        {{ viewingRoomArtwork.viewing_room.name }}
      </h1>
    </div>
    <div class="viewing-room-artwork__image-container">
      <img
        class="viewing-room-artwork__image"
        :src="viewingRoomArtwork.artwork.images[0]"
        :alt="viewingRoomArtwork.artwork.title"
      />
      <Copyright
        class="viewing-room-artwork__copyright"
        :hiddenText="false"
        v-if="viewingRoomArtwork.artwork.copyright"
      >
        {{ `Photo by ${viewingRoomArtwork.artwork.copyright}` }}
      </Copyright>
      <div class="viewing-room-artwork__navigation-container">
        <IconButton
          class="viewing-room-artwork__navigation-button button--control button--control-white"
          :disabled="!viewingRoomArtwork.previous_artwork"
          @click.native="goToPage('prev')"
        >
          <IconArrow class="arrow--left" />
        </IconButton>
        <IconButton
          class="viewing-room-artwork__navigation-button button--control button--control-white"
          :disabled="!viewingRoomArtwork.next_artwork"
          @click.native="goToPage('next')"
        >
          <IconArrow class="arrow--right" />
        </IconButton>
      </div>
    </div>
    <h2 class="viewing-room-artwork__author" v-if="viewingRoomArtwork.artwork.artist">
      {{ viewingRoomArtwork.artwork.artist }}
    </h2>
    <h3
      class="viewing-room-artwork__title"
      v-if="viewingRoomArtwork.artwork.title && viewingRoomArtwork.artwork.is_show_title"
    >
      {{ viewingRoomArtwork.artwork.title }}
    </h3>
    <div
      class="viewing-room-artwork__info viewing-room-artwork__info--first"
      v-if="viewingRoomArtwork.artwork.details || viewingRoomArtwork.artwork.description"
    >
      <h4 class="viewing-room-artwork__info-title">Artwork details</h4>
      <p class="viewing-room-artwork__info-text viewing-room-artwork__info-description">
        {{ viewingRoomArtwork.artwork.description }}
      </p>
      <div class="viewing-room-artwork__info-container">
        <template v-for="(value, key) in viewingRoomArtwork.artwork.details">
          <p class="viewing-room-artwork__info-text" v-if="value">
            <span>{{ key }}</span> - {{ value }}
          </p>
        </template>
      </div>
    </div>
    <div class="viewing-room-artwork__info" v-if="viewingRoomArtwork.artwork.attributes">
      <h4 class="viewing-room-artwork__info-title">Attributes</h4>
      <div class="viewing-room-artwork__info-container">
        <template v-for="(value, key) in viewingRoomArtwork.artwork.attributes">
          <p class="viewing-room-artwork__info-text" v-if="value">
            <span>{{ key }}</span> - {{ value }}
          </p>
        </template>
      </div>
    </div>
    <div class="viewing-room-artwork__info" v-if="viewingRoomArtwork.artwork.related_categories">
      <h4 class="viewing-room-artwork__info-title">Categories</h4>
      <div class="viewing-room-artwork__info-container">
        <p
          class="viewing-room-artwork__info-text"
          v-for="category in viewingRoomArtwork.artwork.related_categories"
        >
          {{ category.name }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "@/components/Loader.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrow from "@/components/icons/IconArrowTop.vue";
import Copyright from "@/components/Copyright.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Loader,
    IconButton,
    IconArrow,
    Copyright,
  },
  data() {
    return {
      loading: false,
      pageTitle: "GH - Viewing Room",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicViewingRooms", {
      viewingRoomArtwork: "getViewingRoomArtwork",
    }),
  },
  async created() {
    this.loading = true;
    const { viewingRoomSlug, artworkSlug } = this.$route.params;
    await this.apiGetViewingRoomArtwork({ viewingRoomSlug, artworkSlug });
    let breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Viewing Room",
        to: `/viewing-room/${viewingRoomSlug}`,
      },
      {
        title: this.viewingRoomArtwork.viewing_room.breadcrumb,
        to: `/viewing-room/${viewingRoomSlug}`,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.pageTitle = `GH - ${this.viewingRoomArtwork.viewing_room.breadcrumb ?? "Viewing Room"}`;
    this.$emit("updateHead");
    this.loading = false;
  },
  watch: {
    "$route.path": {
      async handler() {
        this.loading = true;
        const { viewingRoomSlug, artworkSlug } = this.$route.params;
        await this.apiGetViewingRoomArtwork({ viewingRoomSlug, artworkSlug });
        let breadcrumbs = [
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Viewing Room",
            to: `/viewing-room/${viewingRoomSlug}`,
          },
          {
            title: this.viewingRoomArtwork.viewing_room.breadcrumb,
            to: `/viewing-room/${viewingRoomSlug}`,
          },
        ];
        this.setBreadcrumbs(breadcrumbs);
        this.pageTitle = `GH - ${
          this.viewingRoomArtwork.viewing_room.breadcrumb ?? "Viewing Room"
        }`;
        this.$emit("updateHead");
        this.loading = false;
      },
    },
  },
  methods: {
    ...mapActions("publicViewingRooms", ["apiGetViewingRoomArtwork"]),
    ...mapMutations(["setBreadcrumbs"]),
    goToPage(direction) {
      switch (direction) {
        case "prev": {
          this.$router.push({
            path: `/viewing-room/${this.$route.params.viewingRoomSlug}/${this.viewingRoomArtwork.previous_artwork}`,
          });
          break;
        }
        case "next": {
          this.$router.push({
            path: `/viewing-room/${this.$route.params.viewingRoomSlug}/${this.viewingRoomArtwork.next_artwork}`,
          });
          break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.viewing-room-artwork {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  padding-bottom: 9rem;
  @media only screen and (max-width: $xs) {
    padding-bottom: 6rem;
  }
  &__header {
    margin-top: 16rem;
    margin-bottom: 8rem;
    padding: 0 16rem;
    @media only screen and (max-width: $md) {
      padding: 0 10rem;
    }
    @media only screen and (max-width: $xs) {
      margin-bottom: 6rem;
      padding: 0 25px;
    }

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      color: $white;
      font-size: 6.2rem;
      font-weight: 500;
      line-height: 6.8rem;
      @media only screen and (max-width: $xs) {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
    &_description {
      margin-top: 20px;
      text-align: center;
      font-family: $fontOpenSans;
      color: $white;
      font-size: 2.1rem;
      font-weight: 400;
      line-height: 1.4;
      @media only screen and (max-width: $xs) {
        font-size: 2rem;
      }
    }
  }
  &__image {
    width: 100%;
    height: auto;
    @media only screen and (max-width: $xs) {
      height: 100vw;
    }
    &-container {
      width: 100%;
      padding: 0 16rem;
      position: relative;
      @media only screen and (max-width: $md) {
        padding: 0 10rem;
      }
      @media only screen and (max-width: $xs) {
        padding: 0 25px;
      }
    }
  }
  &__navigation {
    &-container {
      position: absolute;
      padding: 0rem 7rem;
      top: calc(50% - 3.4rem);
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $md) {
        padding: 0 15px;
      }
      @media only screen and (max-width: $xs) {
        padding: 0 32px;
      }
    }
    &-button {
      .arrow {
        &--left {
          margin-right: 4px;
          transform: rotate(-90deg);
        }

        &--right {
          margin-left: 4px;
          transform: rotate(90deg);
        }
      }
    }
  }
  &__author {
    margin-top: 3rem;
    padding: 0 28rem;
    display: inline-block;
    text-align: center;
    font-family: $fontBodoni;
    color: $white;
    font-size: 6.2rem;
    font-weight: 500;
    line-height: 6.8rem;
    @media only screen and (max-width: $md) {
      padding: 0 16rem;
    }
    @media only screen and (max-width: $xs) {
      font-size: 4rem;
      line-height: 6rem;
      padding: 0 25px;
    }
  }
  &__title {
    margin-top: 2rem;
    padding: 0 28rem;
    text-align: center;
    font-family: $fontOpenSans;
    color: $white;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.4;
    @media only screen and (max-width: $md) {
      padding: 0 16rem;
    }
    @media only screen and (max-width: $xs) {
      font-size: 2rem;
      padding: 0 25px;
    }
  }
  &__info {
    margin-top: 8rem;
    width: 100%;
    padding: 0 16.5rem;
    &--first {
      margin-top: 5rem;
    }
    @media only screen and (max-width: $md) {
      padding: 0 10rem;
    }
    @media only screen and (max-width: $xs) {
      margin-top: 5rem;
      padding: 0 25px;
    }
    &-title {
      font-family: $fontBodoni;
      color: $white;
      font-size: 3rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 15px;
      @media only screen and (max-width: $xs) {
        margin-bottom: 10px;
        text-align: center;
      }
    }
    &-text {
      position: relative;
      font-family: $fontOpenSans;
      color: $light4Grey;
      font-size: 2.1rem;
      font-weight: 400;
      line-height: 1.4;
      padding: 0 31px;
      &::after {
        content: "|";
        position: absolute;
        right: 0;
        transform: translateX(50%);
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
        &::after {
          display: none;
        }
      }
      @media only screen and (max-width: $md) {
        font-size: 2rem;
        padding: 0 22px;
      }
      @media only screen and (max-width: $xs) {
        font-size: 2rem;
        padding: 0;
        margin-bottom: 5px;
        text-align: center;
      }
      span {
        font-family: $fontOpenSans;
        color: $light4Grey;
        font-size: 2.1rem;
        font-weight: 400;
        line-height: 1.4;
        @media only screen and (max-width: $xs) {
          font-size: 2rem;
        }
        text-transform: capitalize;
      }
    }
    &-description {
      color: $white;
    }
    &-container {
      margin-top: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media only screen and (max-width: $xs) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
</style>

